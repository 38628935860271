import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ringbearer',
  templateUrl: './ringbearer.component.html',
  styleUrls: ['./ringbearer.component.css']
})
export class RingbearerComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
